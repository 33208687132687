import React from 'react';
import VideoOne from '../component/video/VideoOne';

const DefaultEpisode = () => {

    return (
        <>
            <div className="banner banner-style-1">

                <div className="container">
                    <div className="banner-content">
                        <h1 className="title h1">רשאי להמריא</h1>
                    </div>
                    <div className="banner-content">
                        <div className="col-lg-8">
                            <div className="section-heading heading-left">
                                <p>אני איתי, 51, נשוי, שלושה בנים וכלב. עוסק בתכנה. מתגורר עם משפחתי ביישוב בגליל התחתון.<br />בשנת 2010 טסתי לארה"ב מטעם העבודה. בהמראה חזרה לישראל, בהפתעה גמורה, המטוס החל להיטלטל ומיד לצלול בחדות.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <ul className="shape-group-8 list-unstyled">
                    <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/bubble-9.png"} alt="Bubble" /></li>
                    <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-11.png"} alt="Bubble" /></li>
                    <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/line-4.png"} alt="Line" /></li>
                </ul>

                <ul className="list-unstyled shape-group-21">
                    <li className="shape shape-3">
                        <img src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"} alt="Bubble" />

                    </li>
                    <li className="shape shape-4">
                        <img src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"} alt="Bubble" />
                    </li>
                    <li className="shape shape-5">
                        <img src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"} alt="Bubble" />
                    </li>
                    <li className="shape shape-6">
                        <img src={process.env.PUBLIC_URL + "/images/others/bubble-40.png"} alt="Bubble" />
                    </li>
                    <li className="shape shape-7">
                        <img src={process.env.PUBLIC_URL + "/images/others/bubble-41.png"} alt="Bubble" />
                    </li>


                </ul>
            </div>

            <div className="section-padding">
                <div className="container">
                    <div className="row row-40">
                        <div className="col-lg-6">
                            <div className="single-blog">
                                <div className="single-blog-content blog-grid">
                                    <div className="post-thumbnail">
                                        <img src={process.env.PUBLIC_URL + "/images/mypictures/mefly2.png"} alt="Laptop" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <p>במשך שניות, שנמשכו עבורי כמו נצח, ראיתי את הקרקע מתקרבת במהירות , שמעתי צרחות, ראיתי לפטופים מתעופפים ודיילים שמתחננים בפני הנוסעים להירגע. מאותו הרגע, במהלך עשר שנים, טיסות הפכו עבורי לסיוט. כשמטוס מתחיל לרעוד, אני רואה את ה"סוף".</p>
                            <p>יום אחד זה נמאס.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-padding text-centered">
                <div className="container">
                    <div className="row row-40">
                        <h4 className="title h4">ברוכים הבאים לאתר - Fly &amp; Smile. </h4>
                        <p>&nbsp;</p>
                        <p>היו לי לבטים רבים לפני שהעליתי אותו לאוויר. הרגשתי שעמידה על במה, גם אם מקוונת, ותיאור המסע להוצאת רישיון טיס פרטי, עלולה להיתפס כרברבנות.<br />אבל, שחררתי אותו לחופשי כדי ש"ייגע" בקוראים שמרגישים כמוני. שמצד אחד, כמהים לטייל ולגלות עולם, ומצד שני, נזכרים שעבור זה צריך לטוס.</p>

                        <p><br />ואז, לא מצליחים להירדם ערב הטיסה והבטן מתהפכת. ובטיסה עצמה הופכים חסרי סבלנות. אוחזים בעצמה את גב המושב שלפניהם עם כל רעידה קטנה של המטוס, ממלמלים כמה תפילות, סופרים שניות לאחור, שתיגמר הטיסה הזאת כבר. שתסריט אימה עובר להם בראש, כששומעים בחדשות על התרסקות. שנמנעים מטיסה בכלל.</p>
                        <p>יצרתי את האתר כדי לומר שאפשר להפוך את הטיסה לחוויה מהנה, ולתכנן באופן רגוע את הטיול הבא.<br />זה רק עניין של החלטה.</p>

                        <p><br />כשהחלטתי לעשות משהו עם הפחד הזה, ידעתי שיש טיפולים מתאימים, יחד עם זאת, העדפתי את הדבר האמתי והתחלתי לברר על קורס טיס. היו דילמות והסביבה הרימה גבה, אבל, ברגע שאחזתי בהגאי המטוס בטיסת ההכרות, מול שקיעה וים אינסופי, ידעתי - "אני עושה את זה!<br />יעלה כמה שיעלה וייקח כמה שייקח, אני הולך על זה עד הסוף!&rdquo;</p>

                        <p>&nbsp;</p>
                        <p>כן!<br />ואם כבר, אז לתפוס שלוש ציפורים במכה אחת - <br />אני חוזר לטוס רגוע כמו פעם.<br />אני עושה משהו בלתי אפשרי, שאנצור לעד.<br />אני מקיים הבטחה עתיקה לאשתי &ndash; "להרכיב" אותה על אופנוע (אז מה אם הוא מעופף).</p>
                        <p>הקורס החל בינואר 2020 והשיעורים הראשונים, מידי שבוע, לא ניבאו את מה שעומד להתרחש.</p>

                    </div>
                </div>
            </div>

            <div className="section-padding-equal">
                <div className="center">
                    <img src={process.env.PUBLIC_URL + "/images/mypictures/line5.png"} alt="Laptop" />
                </div>
            </div>

            <div className="section-padding text-centered">
                <div className="container">
                    <div className="row row-40">

                        <p>השיטה בקורס היא מספר שיעורים מעשיים וטיסת יחיד מסכמת (טיסת סולו), בה נמצאים לבד באוויר!<br />במקביל לומדים ונבחנים בשמונה מבחני תאוריה.<br />ככל שמתקדמים, מתווספים נושאים, השיעורים הופכים למאתגרים יותר וטיסות הסולו לארוכות יותר. הן מתבצעות בנתיבי תעופה אזרחיים וכוללות נחיתות בשדות תעופה מגוונים. בסוף יש מבחן מעשי.</p>
                        <p><br />התכנית, מבחינת זמן ובעיקר מבחינה תקציבית, הייתה, להפוך לטייס עד סוף 2020.<br />מישהו כנראה צוחק עלינו. איפה התכניות ואיפה המציאות.</p>
                        <p><br />בחודש הראשון הרגשתי בשמיים. עליתי למטוס בהתלהבות וללא שום חשש, אימצתי את שפת הטייסים והתחלתי להבין איך מטוס לא נופל (זה הרבה יותר פשוט ממה שחושבים). כשנחתה הקורונה, שטף השיעורים פסק. זה הוביל לירידה בביצועים שהצריכה חזרה על נושאים ושיעורים נוספים.</p>
                        <p>&nbsp;</p>

                    </div>
                </div>
            </div>

            {/* נחיתה ראשונה */}
            <section id="firstlanding">
                <div className="section-padding">
                    <div className="container">
                        <div className="center">
                            <img src={process.env.PUBLIC_URL + "/images/mypictures/ballons.png"} alt="Laptop" />
                        </div>
                    </div>
                </div>
            </section>


            <div className="section-padding text-centered">
                <div className="container">
                    <div className="row row-40">

                        <h4 className='title h4'>לבד באויר בפעם הראשונה</h4>
                        <p>בתום שנה! מתחילת הקורס, רחוק מהתכנית המקורית, ביצעתי את טיסת היחיד הראשונה בסביבת המנחת. בסרטון ניתן לראות את הנחיתה הראשונה לבד. אמנם המטוס נחשב לקל, אבל להביא משהו במשקל שלו, לנגיעה מלטפת בקרקע, אינה דבר של מה בכך. פעולות חטופות ואגו יכולים להביא נזק גדול. ולא רק למטוס.<br />הנחיתה לא הייתה מושלמת אבל מילאה אותי בסיפוק -"עשיתי את זה!"</p>
                        <p>&nbsp;</p>

                    </div>
                </div>
            </div>

            <div className="row">
                <div className="centered container">
                    <div className="row">
                        <div className="col-lg-6" style={{ marginTop: '10px', marginBottom: '10px' }}>
                            <VideoOne link='https://www.youtube.com/watch?v=zlTJDydX4PE' img="/images/mypictures/mefirst.png" audio="" />
                        </div>
                        <div className="col-lg-6" style={{ marginTop: '10px', marginBottom: '10px' }}>
                            <VideoOne link='https://www.youtube.com/watch?v=c8OWfGqiPaU' img="/images/mypictures/meland.png" audio="" />
                        </div>
                    </div>
                </div>
            </div>


            {/* אזורים והרוח */}
            <div className="section-padding text-centered">
                <div className="container">
                    <div className="row row-40">

                        <p>הרגשתי מהפך ביחס לטיסות ופתחתי את החלק הבא בהתלהבות. הוא כלל טכניקות שונות והכנה לטיסת יחיד לאזורי האימון. בחלק הזה התחלתי לנהל את הטיסה על כל חלקיה. המדריך לימד, תיקן ושמר שלא אעשה שטויות. באזור האימון, המרוחק מספר קילומטרים מהמנחת, נמצא מטוס אחד ברגע נתון, יש חופש יחסי לנוע בתוכו למקומות ובגבהים מגוונים. האזור מרהיב והטיסה חוויה. לאחר כמה שיעורים עם המדריך, בהם הוכחתי שאני מוכן, יצאתי לאזור האימון לבד. פעם ראשונה עברה בהצלחה.</p>
                        <p><br />בפעם השנייה, רוח מכיוון גבעת המורה הפתיעה אותי. העניין עם הרוח זה שאי אפשר לראות אותה.<br />זה בא פתאום, כמו שנכנסים עם הראש בויטרינה נקייה ושקופה.<br />המטוס החל לרעוד ולאחר מספר שניות קפץ, כאילו היה זה רכב שנתקל בבור בכביש. מצלמת הגופרו, הממוקמת מעלי, שתפסה מעט מהקפיצה, לא הצליחה להקליט כלום ממה שעבר לי בראש באותם רגעים.</p>

                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12" style={{ marginTop: '10px', marginBottom: '10px' }}>
                    <VideoOne link='https://www.youtube.com/watch?v=kWDKEJDxiwQ' img="/images/mypictures/mefly6.png" audio="" />
                </div>
            </div>


            <div className="section-padding text-centered">
                <div className="container">
                    <div className="row row-40">

                        <p>למרות שמזג האוויר היה טוב! הייתי בשליטה ופעלתי נכון, הרוח התזזיתית הזאת נכנסה לי למחזור הדם ולקחה אותי לימים שטיסה הייתה סיוט. דבר אחד היה לי בראש. לא בטוח שמההפתעה הבאה אצא בשלום.<br />לא רק שהרגשתי שאני לא משיג את מה שרציתי, התחלתי לתעב טיסות.</p>
                        <p><br />לאחר כשנה וחצי מתחילת הקורס הרגשתי צורך בהפסקה, למרות שידעתי שהפסקה תגרור ירידה חדה ברמת הכשירות. ידעתי שזה יוביל לשיעורים נוספים והגדלה משמעותית של העלות.<br />קרקעתי את עצמי וניצלתי את התקופה לטובת מבחני התאוריה. זה דווקא עשה לי טוב. הצטיינתי בהם וזה חידש את הביטחון. ההפסקה, בנוסף, פינתה זמן להיזכר ברצון להטיס את אשתי ובכלל, בסיבה העיקרית לקורס הזה.<br />חזרתי לטוס והצלחתי לסיים את השלב לקראת אמצע 2021.</p>

                    </div>
                </div>
            </div>


            {/* המראה */}
            <div className="section-padding">
                <div className="container">
                    <div className="centered">
                        <img src={process.env.PUBLIC_URL + "/images/mypictures/cessnatakeoff.png"} alt="Laptop" />
                    </div>
                </div>
            </div>

            <div className="section-padding text-centered">
                <div className="container">
                    <div className="row row-40">

                        <p>בשלב הבא החלו טיסות לשדות תעופה אחרים. הטיסות ארוכות ומאתגרות יותר, ומבחינתי, סיכוי גבוה לפגוש ברוח הזאת. נדרשו לי כמות שיעורים לא סבירה עם מדריך כדי להיות מוכן. אולי הייתי מוכן אבל לא הרגשתי כך. הייתי ממוקד ברוח.<br />לכן, לא הסכמתי לטוס לבד.</p>

                    </div>
                </div>
            </div>

            <section id="about">
                <div className="section-padding-2">
                    <div className="container">
                        <div className="centered">
                            <img src={process.env.PUBLIC_URL + "/images/mypictures/mefly5.png"} alt="Laptop" />
                        </div>
                    </div>
                </div>
            </section>


            <div className="section-padding text-centered">
                <div className="container">
                    <div className="row row-40">

                        <p>ההמראה מאד מרגשת.<br />אני מעביר את המנוע לכוח מלא. המטוס מתחיל לנוע באטיות מטעה. בתוך כמה שניות הפסים במסלול מתחילים לחלוף בקצב מסחרר. ברגע הנכון אני מתנתק מהקרקע.<br />אני נוסק, מטפס, הקרקע מתרחקת במהירות והכול הופך קטן.<br />אני לבד באוויר. רגע עצמתי!<br />אבל ידעתי, שמרגע זה והלאה, לאורך כל הטיסה, אני תלוי רק בעצמי. לא יכול לעצור לרגע , לקחת אויר ולהמשיך. כל החלטה שלי היא בגדר להיות או לחדול. הפסקתי להאמין שאני מסוגל להתמודד עם זה.</p>
                        <p>באותם ימים לא ראיתי את הרישיון באופק , העלויות הוכפלו והרגשתי שאני "גוזל" מהמשפחה.<br />זה היה קו אדום והודעתי שאני פורש.<br />הסברתי בשיחה על מיצוי ועלויות, למרות שידעתי שהרוח הזאת, היא שמונעת ממני להמשיך.</p>
                        <p><br />בהתחלה עשיתי NEXT בקלות. אבל לאחר כמה ימים זה התחיל להפריע.<br />תמונה, מהטיסה הראשונה, תלויה על הקיר בחדר העבודה. הבטתי בה והרגשתי נורא. אליה הצטרפו קולות מנוע של מטוס, מעל הבית, שהזכירו לי את הוויתור. מעולם לא ויתרתי על משהו.<br />לא הצלחתי להבין איך אחרים לא מתרגשים מהרוח הזאת. התחלתי להרגיש שמשהו לא בסדר אצלי. כול הביטחון בעובדה שהכול אפשרי, התפוגג. זה התחיל להשפיע על תחומים אחרים.<br />נכון, זה רק תחביב, זה לא משהו שאני חייב. שום דבר לא באמת תלוי בזה. ועל רקע הבעיות האמתיות בארץ ובחיים, זה לא היה משהו שצריך להתרגש ממנו. צריך פרופורציות, אבל בכל זאת.... זה עצבן. הרגשתי שאני חייב לנצח את הרוח הזאת. כבר לא משנה יש או אין רישיון.<br /> <br />הייתי חייב באותם ימים איזו תובנה. משפט שאגיד לעצמי לגבי הרוח, ואני עובר את זה. הרי טכנית זה אפשרי. <br />כשהמדריך התעניין החלטתי להיפתח.<br />עד אותו יום , לא אהבתי לדבר על הפחד שלי מהרוח, וההיטלטלות של המטוס. זה הביך אותי.<br />לא היה מה להפסיד ואמרתי לו - "הרוח הזאת מפחידה!"<br />הוא אמר לי, בפליאה, שאני הראשון שמוטרד מרוח בעת השיוט. "אתה אמור להיות מוטרד מרוח בנחיתה" כמו חניכים וטייסים פרטיים אחרים.</p>
                        <p>השיחה הייתה קצרה וספונטנית. יחד עם זאת, הדברים שלו הזיזו אצלי משהו.<br />אחד &ndash; גם טייסים מפחדים (או מוטרדים), אז אני בסדר! מה זה משנה מאיזו רוח מפחדים.<br />שניים &ndash; אם אני לא מוטרד מהרוח בעת הנחיתה אז אדרבא שאני בסדר.</p>
                        <p>שיחה קצרה שלוותה בכמה הסברים טכניים על עמידותו של המטוס ברוח ועל פעולות שיש לבצע, אולי, הפכה אותי לטייס באותו הרגע.</p>
                    </div>
                </div>
            </div>

            {/* חיפה */}
            <section id="haifa">
                <div className="center">
                    <img src={process.env.PUBLIC_URL + "/images/mypictures/line.png"} alt="Laptop" />
                </div>

                <div className="section-padding text-centered">
                    <div className="container">
                        <div className="row row-40">
                            <h4 className='title h4'>הטיסה לחיפה</h4>
                            <p>&nbsp;</p>
                            <p>שינוי דרמטי היה בטיסה לשדה התעופה בחיפה מעל לשנתיים לאחר תחילת הקורס. הדרך לחיפה נושקת לכינרת, פונה מערבה ותוך כמה שניות רואים את הים.&nbsp; יישובי הצפון המוכרים לי נראים כל כך קטנים. הנחיתה בחיפה היא על מסלול נוח שבסופו ים. לאחר המתנה והמראה חזרה, היציאה לים הקסימה אותי. לבד מעל הים ומתחתי ספינות המותירות שובל לבן. בהתקרבות לחדרה, מבט חטוף לכיוון הארובות, שתמיד נראו גבוהות, אפשר לראות את כיוון הרוח (שלא תפתיע יותר).&nbsp; הטיסה לאורך החוף הזכירה לי חזרה מחוץ לארץ. הרגשתי טייס.</p>
                            <p>&nbsp;</p>

                        </div>
                    </div>
                </div>
            </section>
            <div className="row">
                <div className="col-lg-12" style={{ marginTop: '10px', marginBottom: '10px' }}>
                    <VideoOne link='https://www.youtube.com/watch?v=gLC1LHO5F1I' img="/images/mypictures/mefly3.png" audio="" />
                </div>
            </div>


            {/* מצדה */}
            <section id="mzda">

                <div className="section-padding-equal">
                    <div className="center">
                        <img src={process.env.PUBLIC_URL + "/images/mypictures/line5.png"} alt="Laptop" />
                    </div>
                </div>


                <div className="section-padding text-centered">
                    <div className="container">
                        <div className="row row-40">

                            <h4 className='title h4'>לנחות הכי נמוך שאפשר</h4>
                            <p>&nbsp;</p>
                            <p>שיא הקורס, אם נשים את המבחן בצד, היה שנתיים וחצי לאחר תחילתו. ניצבתי מול אתגר של טיסה ארוכה למצדה. הטיסה ארוכה מבחינת מרחק וזמן. ברוב חלקיה משייטים בגובה קבוע, במהירות של כ 165 קמ"ש.<br />מהחלון הקטן ניבטים נופים מרהיבים. חוצים את הארץ ומרגישים כמה היא קטנה.<br />לאורך הנתיב רואים את יישובי הבקעה, מכוניות קטנות שטסות על כביש 90, את ים המלח וכמובן את מצדה. הנתיב נושק לגבול עם ירדן. רוח קלה יכולה לסחוף את המטוס לתחומי ירדן וזה הופך את הטיסה למעניינת. בנוסף למפה הצטיידתי בתכנת ניווט ששמרה עלי.<br />לאחר שעוברים את עין גדי אפשר להתחיל להתכונן לנחיתה במקום הנמוך בעולם.<br />במד הגובה של המטוס אין סימון לגובה מתחת לפני הים וזה מאד מבלבל.<br />הגעתי למנחת ביום "נקי" מפעילויות והייתי המטוס היחיד. יש במקום קפיטריה. עשיתי הפסקה מדברית והמראתי חזרה.<br />הטיסה נערכה יומיים לפני יום העצמאות 2022. באותן שעות התאמנו מטוסי חיל האוויר למטס המסורתי. לא יכולתי שלא להרגיש חלק מהם.</p>
                            <p>&nbsp;</p>

                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12" style={{ marginTop: '10px', marginBottom: '10px' }}>
                        <VideoOne link='https://www.youtube.com/watch?v=mD9u_pf1Uew' img="/images/mypictures/mefly7.png" audio="" />
                    </div>
                </div>
            </section>

            {/* מבחן*/}
            {/* סיום*/}
            <div className="section-padding-equal">
                <div className="center">
                    <img src={process.env.PUBLIC_URL + "/images/mypictures/line5.png"} alt="Laptop" />
                </div>
            </div>
            <div className="section-padding text-centered">
                <div className="container">
                    <div className="row row-40">

                        <h4 className='title h4'>ביום המבחן</h4>
                        <p>לקח לי כשנתיים להבין שאני מסוגל.<br />זה היה ברגע שמצאתי דרך להפוך את הפחד מהרוח, לפעולות פרקטיות כפי שטייס מבצע.<br />אפשר לומר - ברגע שהחלטתי שאני טייס.<br />במבט לאחור זה היה האתגר האמתי ולא ההטסה עצמה.</p>
                        <p><br />ברגע הזה, הרגשתי שאני מתחיל את הקורס.<br />שנה לאחר מכן, לאחר השלמת הטיסה לחיפה ומצדה ולא מעט שיעורי חזרה, התקיים המבחן.<br />בערב שלפני, עזבתי את הטיסה וראיתי סרט.<br />הגעתי למבחן כטייס. טייס שיש לו מה ללמוד. הגעתי אני. לא יותר ולא פחות. באתי ללמוד. משוחרר מכל פחד.<br />הגעתי מחויך , עליתי למטוס וטסתי.<br />הקפדתי על בטיחות. עניתי קצר וברור. תיקנתי טעויות ביוזמתי. דיברתי! שאלתי ולא השארתי דבר בבטן.</p>
                        <p><br />השיעורים, הפחדים, המבחנים והרוח התחברו ביום אחד, בעיתוי מושלם לחוויה בלתי נשכחת.</p>
                        <p><br />הרישיון &ndash; כרטיס פלסטיק לבן, הגיע בדואר שלוש שנים בדיוק! מתחילת הקורס.<br />השיעור הראשון נערך ב 03/01/2020 והרישיון הגיע ב 03/01/2023.<br />הוא נמצא עכשיו בתוך הארנק בין כל מיני כרטיסי מועדון , אשראי וביקור. בכל זאת הוא כרטיס מיוחד.<br />הוא מזכיר לי כמה עצום הפער בין מה שאנשים מאמינים שהם מסוגלים לבין מה שהם מסוגלים בפועל.</p>
                        <p><br />עכשיו אני אוהב לטוס. עכשיו אני טייס!<br />חלומות קמים ונופלים על דברים קטנים. משפט, שאנחנו אומרים לעצמינו, יכול לשנות הכל.<br />אולי נכון שלפעמים חלומות מתגשמים.<br />אני מאמין שחלומות גדולים מתגשמים תמיד!</p>
                    </div>

                </div>
            </div>




            {/* אהבה */}
            <section id="love">
                <div className="center">
                    <img src={process.env.PUBLIC_URL + "/images/mypictures/heart3.png"} alt="Laptop" />
                </div>

                <div className="section-padding">
                    <div className='centered'>
                        <blockquote className="wp-block-quote">
                            <p><span style={{ fontFamily: 'Dana Yad', fontSize: '1.5rem', fontWeight: '600', letterSpacing: '0.3rem' }}>"קצת אהבה לא תזיק..."</span>  <span style={{ fontSize: '1rem' }}>(אלון עדר ולהקה)</span></p><br />

                            <p>וגם תודה...<br />כשנרשמתי לקורס, ציירתי לי תמונה שליוותה אותי לכול אורכו. "אני הטייס. היא יושבת לידי, מחייכת ומצביעה על מקומות. מצלמת, מזהה מיד, ובאזניות מתארת את הנוף". <br />לא הייתי מתחיל את הקורס, ולבטח לא הייתי מסיים, אם היא לא הייתה רוצה לטוס איתי.</p>
                            <p>אין מסדר כנפיים וכובע להעיף לשמיים, אבל יש עוגה טעימה, בדיוק כמו שרציתי.<br />עכשיו אני הטייס הפרטי שלך. אוהב אותך!</p>
                            <p>&nbsp;</p>

                        </blockquote>
                    </div>
                </div>
            </section>

            {/* עוגה*/}

            <div className="section-padding-equal">
                <div className="container">
                    <div className="row row-40">
                        <div className="centered">
                            <img src={process.env.PUBLIC_URL + "/images/mypictures/cake.png"} alt="Laptop" />
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default DefaultEpisode;