import React from 'react';
// import { EpisodesData } from '../data/EpisodesData';

const Cessna = () => {

	return (
		<>

			<div className="banner banner-style-1">
				<div className="container">
					<div className="row align-items-end align-items-xl-start">
						<div className="banner-content">
							<h1 className="title">האוירון</h1>
						</div>
					</div>
					<div className="row align-items-end align-items-xl-start">

						<div className="col-lg-8">
							<div className="banner-content">
								<p>לאווירון שבחרתי יש מנוע אחד. שני מושבים. אף וזנב. הוא נראה צעצוע אבל ממריא , טס גבוה ונוחת. יש לו את הדרישות שלו. המהירות שלו להמראה, לנחיתה ולשיוט.<br />זהו צסנה 152 ובסופו של דבר תמיד עושה מה שהטייס מחליט. אם מכירים אותו טוב, אם בודקים אותו, אם מקפידים על הוראות היצרן והחוק, הטיסה הופכת לחוויה.<br />אפשר ללמוד על מטוס גדול יותר. עניין של עלויות. אפשר אחרי הקורס להרחיב ולשנות את הדגם. העקרונות דומים.<br />המטוס שבחרתי מתאים פחות לאנשים גדולים ברוחב, בגובה ובפרט בשניהם.</p>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="banner-thumbnail">
								<div className="large-thumb">
									<img src={process.env.PUBLIC_URL + "/images/mypictures/listplane.png"} alt="Laptop" />
								</div>
							</div>
						</div>
					</div>

				</div>
				<ul className="list-unstyled shape-group-21">
					<li className="shape shape-3">
						<img src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"} alt="Bubble" />
					</li>
					<li className="shape shape-4">
						<img src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"} alt="Bubble" />
					</li>
					<li className="shape shape-5">
						<img src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"} alt="Bubble" />
					</li>
					<li className="shape shape-6">
						<img src={process.env.PUBLIC_URL + "/images/others/bubble-40.png"} alt="Bubble" />
					</li>
					<li className="shape shape-7">
						<img src={process.env.PUBLIC_URL + "/images/others/bubble-41.png"} alt="Bubble" />
					</li>
				</ul>
			</div>

			<div className="section-padding">
				<div className="container">
					<div className="row row-40">
						{/* <div className="col-lg-6">
							<p>אפשר ללמוד על מטוס גדול יותר. עניין של עלויות. אפשר אחרי הקורס להרחיב ולשנות את הדגם. העקרונות דומים.</p>
						</div> */}
						<div className="col-lg-6">
							<div className="single-blog">
								<div className="single-blog-content blog-grid">
									<div className="post-thumbnail">
										<img src={process.env.PUBLIC_URL + "/images/mypictures/cessna2.png"} alt="Laptop" />
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>

			</div>


		</>
	)
}

export default Cessna;

