import React from 'react';
import TrackVisibility from 'react-on-screen';
import CountUp from 'react-countup';
const CountData = [
    {
        id: 1,
        title: "שעות טיסה",
        count_num: "100"
    },
    {
        id: 2,
        title: "שקלים חדשים",
        count_num: "140000"
    },
    {
        id: 3,
        title: "שעות טיסת יחיד",
        count_num: "16"
    }
]

const Numbers = () => {

    return (
        <>
            <div className="section splash-main-banner">
                <div className="container">
                {/* <h3 className="sub-title"> מפחיד!</h3> */}
                    <div className="row justify-content-center">
                        <div className="col-lg-10">
                            <div className="banner-content">
                                <div className="site-element-count">
                                    {
                                        CountData.map((data) => (
                                            <div className="count-box" key={data.id}>
                                                <span className="count-title">{data.title}</span>
                                                <div className="count-number h2">
                                                    <TrackVisibility once>
                                                        {({ isVisible }) => (
                                                            <span className="number count">
                                                                {isVisible ? <CountUp end={data.count_num} duration={1} /> : null}
                                                            </span>
                                                        )}
                                                    </TrackVisibility>
                                                     {/* <span className="symbol">+</span> */}
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </>
    )
}

export default Numbers;

