import React from 'react';
import SEO from '../component/SEO';
import HeaderOne from '../component/header/HeaderOne';

const TermsOfUse = () => {

    return (
        <>
            <SEO />
            <main className="main-wrapper">
                <HeaderOne />
                <div className="banner banner-style-1">
                    <div className="section section-padding">

                        <div className="container">
                            <div className="row align-items-end align-items-xl-start">
                                <div className="banner-content">
                                    <h1 className="title">חשוב לדעת</h1>
                                </div>
                            </div>
                            <div className="row align-items-end align-items-xl-start">
                                <div className="col-lg-8">
                                    <div className="banner-content">
                                        <p>מטרת האתר לעורר להשראה ולפתוח אפשרויות לכל מי שמעוניין להגשים חלום.<br />חלום כלשהו.</p>
                                        <p>בחרתי את האירועים המשמעותיים מתוך שלל אירועים שחוויתי במהלך שלוש שנים.<br />זאת במטרה להעביר את ההרגשות והתחושות שהיו לי בתקופת לימודי הטיס ואת הסיפוק הרב לאחר הגשמת החלום.</p>
                                        <p>אני בעל רישיון טיס פרטי והאתר לא מיועד ללימודי טיס ואין לקחת ממנו אלא דוגמה שהכול אפשרי.</p>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="banner-thumbnail">
                                        <div className="centered large-thumb">
                                            <img src={process.env.PUBLIC_URL + "/images/mypictures/privatepilot.png"} alt="Laptop" />
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <ul className="list-unstyled shape-group-21">
                            <li className="shape shape-3">
                                <img src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"} alt="Bubble" />
                            </li>
                            <li className="shape shape-4">
                                <img src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"} alt="Bubble" />
                            </li>
                            <li className="shape shape-5">
                                <img src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"} alt="Bubble" />
                            </li>
                            <li className="shape shape-6">
                                <img src={process.env.PUBLIC_URL + "/images/others/bubble-40.png"} alt="Bubble" />
                            </li>
                            <li className="shape shape-7">
                                <img src={process.env.PUBLIC_URL + "/images/others/bubble-41.png"} alt="Bubble" />
                            </li>
                        </ul>
                    </div>

                    <div className="section-padding">
                        <ul className="list-unstyled shape-group-12">
                            <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"} alt="Bubble" /></li>
                        </ul>
                    </div>
                </div>

                {/* <FooterTwo parentClass="" /> */}
            </main>

        </>
    )
}

export default TermsOfUse;