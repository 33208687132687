import React from 'react';

const Contact = () => {
    return (
        <>
            <div className="banner banner-style-1">
                <div className="container">
                    <div className="row align-items-end align-items-xl-start">
                        <div className="banner-content">
                            <h1 className="title">שאלות</h1>
                        </div>
                    </div>


                </div>
                <ul className="list-unstyled shape-group-21">
                    <li className="shape shape-3">
                        <img src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"} alt="Bubble" />
                    </li>
                    <li className="shape shape-4">
                        <img src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"} alt="Bubble" />
                    </li>
                    <li className="shape shape-5">
                        <img src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"} alt="Bubble" />
                    </li>
                    <li className="shape shape-6">
                        <img src={process.env.PUBLIC_URL + "/images/others/bubble-40.png"} alt="Bubble" />
                    </li>
                    <li className="shape shape-7">
                        <img src={process.env.PUBLIC_URL + "/images/others/bubble-41.png"} alt="Bubble" />
                    </li>
                </ul>
            </div>

            <div className="section section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5 col-lg-6 offset-xl-1">
                            <div className="contact-info mb--100 mb_md--30 mt_md--0 mt--150">
                                <h4 className="title">נייד</h4>
                                <p>אשמח לענות על כל שאלה לגבי החוויה האישית בקורס</p>
                                <h4 className="phone-number"><a href="tel:1234567890">(123) 456 7890</a></h4>
                            </div>
                            <div className="contact-info mb--30">
                                <h4 className="title">Email</h4>
                                <p>אשמח לענות על כל שאלה לגבי החוויה האישית בקורס</p>
                                <h4 className="phone-number"><a href="mailto:info@example.com">info@example.com</a></h4>
                            </div>
                        </div>
                    </div>
                </div>
                <ul className="list-unstyled shape-group-12">
                    <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"} alt="Bubble" /></li>
                    <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"} alt="Bubble" /></li>
                    <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/circle-3.png"} alt="Circle" /></li>
                </ul>
            </div>
        </>
    )
}

export default Contact;