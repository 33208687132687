import TheCourse from '../pages/TheCourse';
import Cessna from '../pages/Cessna';
import Contact from '../pages/Contact';
import TermsOfUse from '../pages/TermsOfUse';

export const EpisodesData = [
    {
        "id": 1,
        "link": "The Course",
        "title": "הקורס",
        "image": "/images/mypictures/me1.png",
        "body":TheCourse
    },
    {
        "id": 2,
        "link": "Cessna",
        "title": "האוירון",
        "image": "/images/mypictures/me1.png",
        "body":Cessna
    },
    {
        "id": 3,
        "link": "Contact",
        "title": "שאלות",
        "image": "/images/mypictures/me1.png",
        "body":Contact
    },
    {
        "id": 4,
        "link": "Terms Use",
        "title": "כמה כללים ברורים",
        "image": "/images/mypictures/me1.png",
        "body":TermsOfUse
    }

];