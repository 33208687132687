import React from 'react';
import { useParams } from 'react-router-dom';
import SEO from '../component/SEO';
import HeaderOne from '../component/header/HeaderOne';
import FooterTwo from '../component/footer/FooterTwo';
import { createElement } from 'react';
import { EpisodesData } from '../data/EpisodesData';
import { slugify } from '../utils';
import DefaultEpisode from './DefaultEpisode';

function EpsisodeBody({ episode }) {
    return createElement(
        episode.body
    );
}



const MainPage = () => {

    const params = useParams();
    const episodeSlug = params.slug;
    const Episodes = EpisodesData.filter(data => slugify(data.link) === episodeSlug);
    const Episode = Episodes[0];

    return (
        <>
            <SEO/>
            <main className="main-wrapper">
                <HeaderOne />
                {params.slug === undefined ? <DefaultEpisode /> : <EpsisodeBody episode={Episode} />}
                <FooterTwo parentClass="" />
            </main>
        </>
    )
}

export default MainPage;

