import React from 'react';
import { Link } from 'react-router-dom';


const FooterTwo = () => {

    return (
        <footer className="footer-area footer-dark">
            <div className="container">
                <div className="footer-bottom">
                <div className="row">
                    <div className="col-md-6">
                    <div className="footer-copyright">
                        {/* <span className="copyright-text">© {new Date().getFullYear()}. All rights reserved by <a href="https://70kgless.com/">Acheive With Me</a>.</span> */}
                        <span className="copyright-text">זכויות שמורות <a href="http://www.70kgless.com">לאיתי להישגים</a>© 2017</span>
                    </div>
                    </div>
                    <div className="col-md-6">
                    <div className="footer-bottom-link">
                        <ul className="list-unstyled">
                            <li><Link to={process.env.PUBLIC_URL + "/terms-use"}>חשוב לדעת</Link></li>
                        </ul>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </footer>
    )
}

export default FooterTwo;