import React from 'react';
import { HashLink } from 'react-router-hash-link';
import FYI from '../../elements/logo/FYI';

const Nav = () => {
    return (
        <nav className="mainmenu-nav">
            <ul className="mainmenu">
                <li><HashLink smooth to={process.env.PUBLIC_URL + "/#firstlanding"}>לבד באויר פעם ראשונה</HashLink></li>
                <li><HashLink smooth to={process.env.PUBLIC_URL + "/#love"}>לטוס איתה</HashLink></li>
                <li><HashLink smooth to={process.env.PUBLIC_URL + "/#haifa"}>חיפה</HashLink></li>
                <li><HashLink smooth to={process.env.PUBLIC_URL + "/#mzda"}>מצדה</HashLink></li>
                <li><HashLink smooth to={process.env.PUBLIC_URL + "/cessna"}>האוירון</HashLink></li>
                <li><HashLink style={{ color: 'red' }} to={process.env.PUBLIC_URL + "/terms-use"}>חשוב לדעת</HashLink></li>
                <li><HashLink style={{ color: 'blue' }} to={process.env.PUBLIC_URL + "/contact"}>שאלות</HashLink></li>
            </ul>
        </nav>
    )
}

export default Nav;