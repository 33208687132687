import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ScrollToTop from './component/scrollToTop/ScrollToTop';
import 'bootstrap/dist/css/bootstrap.rtl.min.css';

// Pages 
import MainPage from './pages/MainPage';
// import Contact from './pages/Contact';
import ErrorPage from './pages/404';
import TermsOfUse from './pages/TermsOfUse';


// Css Import
import './assets/scss/app.scss';


const App = () => {
	return (
		<Router>
			<ScrollToTop>
				<Routes>

					<Route path={process.env.PUBLIC_URL + "/"} element={<MainPage />} />
					<Route path={process.env.PUBLIC_URL + "/:slug"} element={<MainPage />} />
					<Route path={process.env.PUBLIC_URL + "/terms-use"} element={<TermsOfUse />} />

					{/* Pages  */}
					<Route path={process.env.PUBLIC_URL + "/404"} element={<ErrorPage />} />

				</Routes>
			</ScrollToTop>
		</Router>
	)
}

export default App;
