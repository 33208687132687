import React from 'react';
import { HashLink } from 'react-router-hash-link';

const Logo = ({limage, dimage, simage}) => {
    return (
        <HashLink smooth to={process.env.PUBLIC_URL + "/#top"}>
            <img className="light-version-logo" src={process.env.PUBLIC_URL + limage} alt="logo" />
            <img className="dark-version-logo" src={process.env.PUBLIC_URL + dimage} alt="logo" />
            <img className="sticky-logo" src={process.env.PUBLIC_URL + simage} alt="logo" />
        </HashLink>
    )
}


export default Logo;