import React from 'react';
import Numbers from '../component/Numbers/Numbers';


const TheCourse = () => {

	return (
		<>
			<main className="main-wrapper">

				<div className="banner banner-style-1">
					<div className="container">
						<div className="row align-items-end align-items-xl-start">
							<div className="banner-content">
								<h1 className="title">הקורס</h1>
							</div>
						</div>
						<div className="row align-items-end align-items-xl-start">

							<div className="col-lg-8">
								<div className="banner-content">
								<p>מטרת הקורס, המוזכר באתר זה, היא הענקת רישיון טיס פרטי למטוס קל, חד מנועי (בוכנה). הרישיון מתיר טיסת ראיה בלבד , כלומר, בשעות היום ולא בתוך עננים או כל דבר אחר המונע ראות ארוכת טווח וקשר מתמיד עם הקרקע. <br />הקורס כולל לימודי תיאוריה, שיעורים מעשיים ומבחן.<br />אפשר להפוך לטייס פרטי בתוך חצי שנה וקורה שזה נמשך מספר שנים. משך הקרוס כמו גם עלותו תלוי בעיקר בחניך אך לא רק.</p>
								</div>
							</div>
							<div className="col-lg-4">
								<div className="banner-thumbnail">
									<div className="large-thumb">
										<img src={process.env.PUBLIC_URL + "/images/mypictures/c152_4.png"} alt="Laptop" />
									</div>
								</div>
							</div>
						</div>

					</div>
					<ul className="list-unstyled shape-group-21">
						<li className="shape shape-3">
							<img src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"} alt="Bubble" />
						</li>
						<li className="shape shape-4">
							<img src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"} alt="Bubble" />
						</li>
						<li className="shape shape-5">
							<img src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"} alt="Bubble" />
						</li>
						<li className="shape shape-6">
							<img src={process.env.PUBLIC_URL + "/images/others/bubble-40.png"} alt="Bubble" />
						</li>
						<li className="shape shape-7">
							<img src={process.env.PUBLIC_URL + "/images/others/bubble-41.png"} alt="Bubble" />
						</li>
					</ul>
				</div>

				<div className="section-padding">
					<Numbers />
					<div className="container">
						<div className="row">
							<div className="col-lg-12">

								<p>לקורס נדרשת בדיקה רפואית ושליטה בשפה האנגלית.<br />לימודי התאוריה כוללים שמונה נושאים ונבחנים עליהם בשמונה מבחנים נפרדים. אפשר ללמוד עצמאית. אפשר בקבוצה. אפשר עם הדרכה או בלעדיה.<br />את חובת מבחני התאוריה, בעלי תוקף של שנתיים, רצוי להשלים בעיתוי שיאפשר את השלבים המתקדמים של החלק המעשי.</p>
								<p>הכרת המטוס מחולק לשני מבחנים. מכירים כל בורג במטוס, המשקל שלו ואיך נכון להעמיס אותו. איך מטוס לא נופל ושאר שאלות נענות בקורס ידע טכני. החוקה מסבירה מה חל על טייס פרטי בכלל ובפרט בארץ. אחרי שלומדים מטאורולוגיה הופכים לחזאים, הנווטות מאפשר הכרות עם כדור הארץ, צפון ושאר הכיוונים, הבדלי זמן בין מקומות ובכלל איך להתנהג בנתיב תעופה. התקשורת היא חשובה ביותר ולומדים עליה בקורס רדיו טלפון. איך מתקשרים עם הבקר ושאר המטוסים. איך מזהים כיוון מטוס בלילה. לומדים על הטייס כאדם ועל ביצועי האנוש שלו.</p>
								<p>החלק המעשי הוא למעשה טיסות עם מדריך צמוד. בכול שיעור טסים ולומדים לפי תכנית. זו טיסת חוויה בפני עצמה. רואים ומכירים את הארץ מזווית חדשה. לפני הטיסה ואחריה יש תחקיר והסברים.<br />לפני כול טיסה מתבצעת בדיקות ורישומים כגון, האם הטיסה מאושרת, המטוס תקין, מזג אויר מתאים, אישור לטיסה, תדלוק ורישום.</p>
								<p>ומה קורה במבחן? זה עוד שיעור המחולק לשתי טיסות. <br />לאחר מעבר המבחן, משלמים עוד אגרה מחכים ויש רישיון.</p>

							</div>
						</div>
					</div>
				</div>

			</main>
		</>
	)
}

export default TheCourse;

