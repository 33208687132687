import React, { useState } from 'react';
import { FaPlay } from 'react-icons/fa';
import FsLightbox from 'fslightbox-react';
// import { useEffect, useRef } from 'react';

const VideoOne = ({ link, img, audio }) => {

    const [toggler, setToggler] = useState(false);
    // const [audioon, setsudioon] = useState(false);

    // let audio = new Audio(process.env.PUBLIC_URL + "/a2.mp3")

    // const myRef = useRef();

    // useEffect(() => {
    //     if (audioon == true) {
    //         audio.play();
    //     }
    //     else {
    //         audio.pause();
    //     }
    //     console.log(audioon);

    // }, [audioon]);

    // const startAudio = () => {
    //     if ({ audio } !== null) {
    //         myRef.current.play();
    //         // // changeAudioStatus(true);
    //     }
    // };

    // const pauseAudio = () => {
    //     console.log("here");
    //     myRef.current.pause();
    //     myRef.current.currentTime = 0;
    //     // // changeAudioStatus(false);
    // };

    return (
        <>
            <div className="section-padding-equal">
                {/* <div className="container"> */}
                <div className="about-expert">
                    <div className="thumbnail">
                        <img src={process.env.PUBLIC_URL + img} alt="Thumbnail" />
                        <div className="popup-video">
                            <button className="play-btn"
                                // onClick={() => { setToggler(!toggler); startAudio(); }}>
                                onClick={() => { setToggler(!toggler); }}>
                                <FaPlay />
                            </button>
                        </div>
                    </div>
                </div>
                {/* <audio
                    ref={myRef}
                    // src={process.env.PUBLIC_URL + "/a9.mp3"}
                    src={process.env.PUBLIC_URL + audio}
                /> */}
                {/* </div> */}
                {/* <ul className="shape-group-16 list-unstyled">
                    <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/circle-2.png"} alt="circle" /></li>
                    <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"} alt="Line" /></li>
                    <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"} alt="Line" /></li>
                </ul> */}
                {/* </div> */}
                {/* <FsLightbox onClose={() => { console.log("afddf"); pauseAudio(); }} toggler={toggler} sources={[link]} /> */}
                <FsLightbox onClose={() => { console.log("afddf"); }} toggler={toggler} sources={[link]} />
            </div>
        </>
    )
}


export default VideoOne;